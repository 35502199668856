import React from "react";
import { DialogTitle, DialogContent, Box, Divider, Typography, Grid } from "@material-ui/core";
import { from, BehaviorSubject, combineLatest } from "rxjs";
import { filter, mergeMap, debounceTime, distinctUntilChanged, } from "rxjs/operators";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";
import { AgGridUtil } from "../../../../shared/services/ag-grid/agGridUtil";
import AgGridEditButtonCellRendererComponent from "../../../../shared/components/elements/agGridEditButtonCellRendererComponent";
import ChildMessageRendererComponent from "../../../../shared/components/childMessageRendererComponent";
import { AuthContext } from "../../../../shared/store/authProvider";
import { API_ENDPOINT, CrudAction, ResultStatus } from "../../../../shared/types/enums";
import PageLoadingComponent from "../../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../../shared/components/page/pageErrorComponent";
import { MatIconService } from "../../../../shared/services/theme/matIconService";
import { DataService, SubscriptionArray } from "../../../../shared/services/dataService";
import ProfileTransactionService from "./profileTransactionService";
import LayoutService from "../../../../shared/services/layoutService";
import ToastService from "../../../../shared/services/toastService";
import { AgGridColumnExt } from "../../../../shared/services/ag-grid/agGridColumnExt";
import RolePermissionService from "../../../../shared/role-permissions/rolePermissionService";
import TransactionDetailDialogComponent from "../../../transaction-center/transaction-detail/transactionDetailDialogComponent";
import ApiService from "../../../../shared/services/apiService";
import ProfileDetailCellRendererComponent from "../profileDetailCellRendererComponent";
import ProfileDetailDialogComponent from "../profileDetailDialogComponent";

const fetchProfilesBySearchBoxText = async (searchBoxParams) => {
  const rowData = await fetch(
    `${process.env.REACT_APP_CORE_HTTP}/Profiles/GetProfileCount`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sourceid: searchBoxParams.sourceId,
        fileid: String(searchBoxParams.fileId),
        healthindicator: "",
        tenantid: searchBoxParams.user.tenantId,
        searchvalue: searchBoxParams.newValue,
        pageNumber: 1,
        rowsperPage: 250,
        isActive: 1,
        screenId: 0,
        userId: searchBoxParams.user.userId,
        searchString: "",
        taskid: 0,
        source: "Profiles"
      }),
    }
  ).then((result) => result.json());
  return rowData;
};
let searchSubject = new BehaviorSubject("");
let SearchResultObservable = searchSubject.pipe(
  filter((val) => val.hasOwnProperty('newValue')),
  debounceTime(750),
  distinctUntilChanged(),
  mergeMap((val) => from(fetchProfilesBySearchBoxText(val)))
);

class ProfileTransactionComponent extends React.Component {
  static contextType = AuthContext;

  oSubscriptions = new SubscriptionArray();
  constructor(props) {
    super(props);
    // init state
    this.state = {
      data: [],
      fetchResult: ResultStatus.NOT_LOADED,
      isEditing: this.props.inputAction === CrudAction.UPDATE,
      porzioSearch: "",
      showCreateNewTransactionDialog: false,
      agGridUtils: new AgGridUtil("lastname", {
        inlineEditButtonCellRendererComponent: AgGridEditButtonCellRendererComponent,
        childMessageRendererComponent: ChildMessageRendererComponent,
        profileDetailCellRendererComponent: ProfileDetailCellRendererComponent,
      }),
    };
    this.fetchData.bind(this);
    this.handleFilterChange.bind(this);
  }

  componentWillUnmount() {
    this.oSubscriptions.cancelAll();
  }

  componentDidMount() {
    if (this.state.isEditing) {
      this.fetchData();
      this.subscription = SearchResultObservable.subscribe((searchResultJson) => {
        this.setState({ data: searchResultJson });
      });
    }
  }

  /** API Fetch */
  fetchData = () => {
    this.oSubscriptions.cancelAll();
    this.setState({ fetchResult: ResultStatus.LOADING, data: [] });
    // save the subscription object
    this.oSubscriptions.add(
      ProfileTransactionService.getProfileTransactionsOBS(this.props.modalAgNode, this.context)
        .subscribe(
          // success
          (_data) => {
            //_data = new Array(_data);
            this.setState({ data: DataService.hasElements(_data) ? _data : [] },
              // change the state after all the above are assigned
              () => {
                this.setState({ fetchResult: ResultStatus.LOADED });
                //this.state.agGridUtils.sizeColumnsToFit();
              }
            );
          },
          // onError
          (error) => {
            ToastService.showError("Error Occured");
            this.setState({ fetchResult: ResultStatus.ERROR });
          }
        )
    );
  };

  methodFromParent = (row_col, node) => {
    //TODO : row_col should be passed separately with the values instead of a string to avoid below checks 
    //as in this instance trid and prid both might be present for a record   
    const profileIdStr = "Porzio GST Profile ID";
    const transactionIdStr = "Porzio GST Transaction ID";

    this.setState({
      modalAgNode: {
        ...node,
        ...(row_col.includes(transactionIdStr) && { transactionid: node.trid }),
        ...(row_col.includes(profileIdStr) && { prid: node.prid }),
      }
    });

    if (this.state.agGridUtils.isNotEditing()) {
      row_col.includes(profileIdStr) ? this.setState({ showProfileDetailDialog: true })
        : this.setState({ showTransactionDetailDialog: true });
    }
  }

  handleFilterChange = () => {
    fetch(`${process.env.REACT_APP_BASE_HTTP}/Profiles/${this.context.user.tenantId}/FetchProfiles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pageNum: 1,
        pageSize: 25,
        source: "Profiles",
        searchBoxText: "", //searchBoxText,
        recycle: "1",
        sourceId: 24,
        permissions: null,
        userType: this.context.user.userTypeId,
        fileId: 5069,
        healthIndicatorId: null,
        SearchString: "",
        UserId: this.context.user.userId,
        ScreenId: 43,
        TaskId: 0,
      }),
    })
      .then((result) => result.json())
      .then((rowData) => this.setState({ data: rowData }))
      // .then((rowData) => console.log(rowData))
      .catch((err) => console.log(err));
  };
  handleSearchChange = (e) => {
    const newValue = e.target.value;
    this.setState({ porzioSearch: newValue });
    searchSubject.next({ newValue, ...this.props.modalAgNode, ...this.context });
  };

  TAB_PERMISSIONS = RolePermissionService.PROFILE_TRANSACTIONS;
  render() {
    const { classes } = this.props;
    this.props.tabConfig.ref = this; // 1/4) required by parent component

    if (this.TAB_PERMISSIONS.cannotView) {
      return RolePermissionService.getAccessDeniedComponent(); // this is required to prevent Url navigation
    }
    else {
      const isReadOnly = this.props.isReadOnly || this.TAB_PERMISSIONS.cannotEdit;
      this.state.agGridUtils.setReadOnlyMode(isReadOnly);

      switch (this.state.fetchResult) {
        case ResultStatus.NOT_LOADED:
        case ResultStatus.LOADING:
          return (<PageLoadingComponent small classes={classes} label="Loading Transactions" />);
        case ResultStatus.LOADED:
        case ResultStatus.SUCCESS:
          return (
            <React.Fragment>
              <DialogTitle disableTypography id="dialogTitle" />
              <DialogContent>
                <React.Fragment key={"addressFragment"}>

                  <ProfileDetailDialogComponent standAloneProps={this.props.standAloneProps ? LayoutService.getContainedDialogProps(false) : null}
                    inputAction={CrudAction.UPDATE} modalAgNode={this.state.modalAgNode}
                    open={this.state.showProfileDetailDialog || false} onClose={() => this.setState({ showProfileDetailDialog: false })} />

                  {/* View Transaction Contained-Dialog as a Page */}
                  {!this.state.showTransactionDetailDialog ? null :
                    <TransactionDetailDialogComponent inputAction={CrudAction.UPDATE} modalAgNode={this.state.modalAgNode} recipientProfileId={null}
                      onClone={null} transactionToClone={null} // clone stuff; null-> hides the clone icon
                      open={this.state.showTransactionDetailDialog || false} onClose={() => { this.setState({ showTransactionDetailDialog: false }); }} />}

                  {/* Add New Transaction Contained-Dialog as a Page */}
                  {!this.state.showCreateNewTransactionDialog ? null :
                    <TransactionDetailDialogComponent standAloneProps={LayoutService.getContainedDialogProps(false)}
                      inputAction={CrudAction.CREATE} modalAgNode={{}} preloadedRecipientProfileId={this.props.modalAgNode.prid}
                      onClone={null} transactionToClone={null} // clone stuff; null-> hides the clone icon
                      open={this.state.showCreateNewTransactionDialog || false}
                      onClose={(_result) => {
                        this.setState({ showCreateNewTransactionDialog: false });
                        if (_result && _result !== false && _result.trid > 0) { this.fetchData(); }
                        else { this.setState({ showCreateNewTransactionDialog: false, }); } // just hide
                      }}
                    />
                  }

                  <Box style={{ padding: "0px" }}>
                    <div id="MainRoleGrid">
                      <Grid container direction="row" justify="space-between" alignItems="center">
                        <Typography variant="h6" className={classes.sectionHeader} style={{ margin: 8 }}></Typography>
                        {LayoutService.getIconButton((this.props.isReadOnly || this.TAB_PERMISSIONS.cannotCreate), MatIconService.ADD_CIRCLE_OUTLINE,
                          "Add", () => { this.setState({ showCreateNewTransactionDialog: true }) })}
                      </Grid>
                      <div {...LayoutService.getAgGridStyles(264)}>
                        <AgGridReact
                          pagination={true}
                          paginationPageSize={100}
                          rowData={this.state.data}
                          columnDefs={ProfileTransactionService.getColumnDefs(this)}
                          frameworkComponents={this.state.agGridUtils.frameworkComponents}
                          suppressClickEdit={true}
                          gridOptions={{
                            context: { componentParent: this },
                            //suppressContextMenu: true,
                            ...AgGridColumnExt.getGridOptions(56),
                            ...this.state.agGridUtils.bindInlineEditEvents(),
                          }}
                          onGridReady={(params) => {
                            this.state.agGridUtils.setGridParams(params, false);
                            this.state.agGridUtils.setReadOnlyMode(isReadOnly);
                          }}
                        />
                      </div>
                    </div>
                  </Box>
                  <Divider />
                </React.Fragment>
              </DialogContent>
            </React.Fragment>
          );

        case ResultStatus.ERROR:
        default:
          return (<PageErrorComponent small label="Error Loading Profile Transaction Details" classes={classes} onRetry={() => { this.fetchData(); }} />);
      }

    }
  }

  /** 2/4 Required */
  isDirtyCallback = () => {
    // do any additional checkings if needed
    return false;
  }
  /** 3/4 Required in Parent */
  resetCallback = () => {
    this.fetchData();
    // do any additional resetting if needed
  }

  /** 4/4 Required in Parent */
  postCallbackOBS = () => {
    return null; // not required handled incomponent like below
  }

  //---
}
/** HOC */
export default LayoutService.getHocComponenet(ProfileTransactionComponent);