import React, { Component } from "react";
import { combineLatest, } from "rxjs";
import clsx from "clsx";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise/dist/styles/ag-theme-balham-dark.css";

import { AuthContext } from "../../../shared/store/authProvider";
import PageLoadingComponent from "../../../shared/components/page/pageLoadingComponent";
import PageErrorComponent from "../../../shared/components/page/pageErrorComponent";
import { SubscriptionArray } from "../../../shared/services/dataService";
import { ResultStatus, CrudAction } from "../../../shared/types/enums";
import LayoutService from "../../../shared/services/layoutService";
import { Typography, Divider, Grid, Tooltip, IconButton, MenuItem, Select } from "@material-ui/core";
import MatSpecService from "../../../shared/services/theme/matSpecService";
import { MatIconService } from "../../../shared/services/theme/matIconService";

import ChildMessageRendererComponent from "./compliance-default-details/childMessageRendererComponent";
import ComplianceDefaultMappingService from "./complianceDefaultMappingService";
import ComplianceDefaultDialogComponent from "./compliance-default-details/complianceDefaultDialogComponent";
import RolePermissionService from "../../../shared/role-permissions/rolePermissionService";

class ComplianceDefaultMappingComponent extends Component {
    static contextType = AuthContext;
    apiSubscriptions = new SubscriptionArray();

    constructor(props) {
        super(props);
        this.state = {
            frameworkComponents: {
                childMessageRendererComponent: ChildMessageRendererComponent,
            },
            showDialog: false,
            modalAgNode: null,
            selectedEntityId: 0,
            selectedFieldId: 0,
        };
    }

    componentWillUnmount() {
        this.apiSubscriptions.cancelAll();
    }

    componentDidMount() {
        this.fetchData();
    }

    /** API Fetch */
    fetchData = (_ignoreCache = false) => {
        this.apiSubscriptions.cancelAll();
        this.setState({ fetchResult: ResultStatus.LOADING, data: [] });

        this.apiSubscriptions.add(
            combineLatest([
                ComplianceDefaultMappingService.getPorzioFieldListAsOBS(this.context.user.tenantId),
                ComplianceDefaultMappingService.getEntityListAsOBS(this.context.user.tenantId),
                ComplianceDefaultMappingService.getComplianceEntityFieldsAsOBS(this.context.user.tenantId, 1),
                ComplianceDefaultMappingService.getComplianceEntityFieldsAsOBS(this.context.user.tenantId, 2),
            ]).subscribe(
                // success
                ([_data, _entityList, _profileFieldList, _transactionFieldList]) => {

                    //remove spciality and sort //gst_1889:get the speciality and product into the dropdown and move it to db.
                    _profileFieldList = _profileFieldList.sort((a, b) => a.fieldName.localeCompare(b.fieldName));
                    //remove product and sort
                    _transactionFieldList = _transactionFieldList.sort((a, b) => a.fieldName.localeCompare(b.fieldName));
                    // combine product and transactiony, then sort
                    const _profileAndTransactionFieldList = ([..._profileFieldList, ..._transactionFieldList]).sort((a, b) => a.fieldName.localeCompare(b.fieldName));

                    this.setState(
                        {
                            data: _data,
                            entityList: [{ entityId: 0, entityName: "All" }, ..._entityList],
                            profileFieldList: _profileFieldList,
                            transactionFieldList: _transactionFieldList,
                            profileAndTransactionFieldList: _profileAndTransactionFieldList,
                            fieldList: [{ fieldId: 0, fieldName: "All" }, ..._profileAndTransactionFieldList],
                        },
                        // change the state after all the above are assigned
                        () => {
                            this.setState({ fetchResult: ResultStatus.LOADED });
                        }
                    );
                },
                // onError
                (error) => {
                    console.log("Error:", error);
                    this.setState({ fetchResult: ResultStatus.ERROR });
                }
            )
        );
    };

    onGridReady = (event) => {
        event.api.closeToolPanel();
        event.api.sizeColumnsToFit();
        this.gridApi = event.api;
        this.gridColumnApi = event.columnApi;
    };

    onEntitySelectClick = (event) => {
        console.log(event);
        this.setState({ selectedEntityId: event.target.value });

        switch (event.target.value) {
            case 0:
                this.setState({ fieldList: [{ fieldId: 0, fieldName: "All" }, ...this.state.profileAndTransactionFieldList] });
                this.setState({ selectedFieldId: 0 });
                break;
            case 1:
                this.setState({ fieldList: [{ fieldId: 0, fieldName: "All" }, ...this.state.profileFieldList] });
                break;
            case 2:
                this.setState({ fieldList: [{ fieldId: 0, fieldName: "All" }, ...this.state.transactionFieldList] });
                break;
            default:
                this.setState({ selectedEntityId: event.target.value });
        }

        this.gridApi.setFilterModel(null);

    }

    onFieldSelectClick = (event) => {
        this.setState({ selectedFieldId: event.target.value });
        this.gridApi.setFilterModel({
            fieldName: {
                filterType: 'text',
                type: 'equals',
                filter: event.target.value === 0 ? null : this.state.fieldList.find(x => x.fieldId === event.target.value).fieldName,
            },
        });
    }

    onDownloadClick = () => {
        let api = this.gridApi, params = this.getParams();
        api.exportDataAsExcel(params);
    }

    getParams() {
        return {
            // allColumns: true,
            columnKeys: this.gridColumnApi.getAllColumns().filter(c => c.colDef.headerName !== ""),
            fileName: `Compliance_Default_Mapping ${new Date().toDateString()}`
        };
    }

    methodFromParent = (cell, data) => {
        this.setState({
            showDialog: true,
            modalAgNode: data,
            inputAction: CrudAction.UPDATE,
        });
    };

    handleCreate = () => {
        this.setState({
            showDialog: true,
            inputAction: CrudAction.CREATE,
        });
    }

    render() {
        const { classes } = this.props;
        const pageName = "Default Mapping";
        if (RolePermissionService.DEFAULT_MAPPING_LIST.cannotView) {
            return RolePermissionService.getAccessDeniedComponent(classes, () => { this.props.history.goBack(); });
        } else {
            switch (this.state.fetchResult) {
                case ResultStatus.NOT_LOADED:
                case ResultStatus.LOADING:
                    return (<PageLoadingComponent classes={classes} label={`Loading ${pageName}`} />);
                case ResultStatus.LOADED:
                case ResultStatus.SUCCESS:
                    return (
                        <div id="MainUsersGrid">
                            <div className="IconBg marginTop10px tableTitleHead">
                                <CompliancePageHeaderComponent back history={this.props.history} noDivider
                                    classes={classes} label={pageName} entityComplianceSelect
                                    selectedEntityId={this.state.selectedEntityId}
                                    entitySelectData={this.state.entityList}
                                    onEntitySelectClick={(event) => { this.onEntitySelectClick(event); }}
                                    fieldComplianceSelect
                                    selectedFieldId={this.state.selectedFieldId}
                                    fieldSelectData={this.state.fieldList}
                                    onFieldSelectClick={(event) => { this.onFieldSelectClick(event); }}
                                    download onDownloadClick={this.onDownloadClick}
                                    addCircle onAddClick={this.handleCreate}
                                />
                            </div>
                            <div {...LayoutService.getAgGridStyles()}>
                                <AgGridReact
                                    columnDefs={ComplianceDefaultMappingService.getColumnDefs(this)}
                                    rowData={this.state.data}
                                    pagination={true}
                                    paginationPageSize={50}
                                    frameworkComponents={this.state.frameworkComponents}
                                    gridOptions={{
                                        context: { componentParent: this },
                                    }}
                                    onGridReady={(event) => {
                                        this.onGridReady(event);
                                    }}
                                ></AgGridReact>
                            </div>

                            {this.state.showDialog ? <ComplianceDefaultDialogComponent inputAction={this.state.inputAction} modalAgNode={this.state.modalAgNode}
                                open={this.state.showDialog || false} onClose={() => this.setState({ showDialog: false })} refreshList={this.fetchData} />
                                : <></>}

                        </div>
                    );

                case ResultStatus.ERROR:
                    return (<PageErrorComponent label={`Error Loading ${pageName}`} classes={classes} onRetry={() => { this.fetchData(true); }} />);
                default:
                    return null;
            }
        }
    }
}

class CompliancePageHeaderComponent extends Component {
    render() {
        const { small, classes, history, label, back, menu, search, entityComplianceSelect, fieldComplianceSelect, countrySelect, importTemplate, uploadFile, save, add, addCircle, view, reload, ok, cancel, noDivider, noDividerSpacing, download } = this.props;
        const showButtons = back || menu || search || entityComplianceSelect || countrySelect || importTemplate || uploadFile || save || add || addCircle || view || reload || ok || cancel || download;

        return (
            <React.Fragment>
                <Grid className={classes.flexNoShrink} container direction="column">
                    <Grid container direction="row" alignItems="center">
                        {back ? (
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Back" placement="bottom" arrow>
                                <IconButton onClick={() => { history.goBack(); }}>{MatIconService.BACK}</IconButton>
                            </Tooltip>
                        ) : null}

                        <Typography variant={small ? "h6" : "h5"} className={classes.headerTitle}>
                            {label}
                        </Typography>

                        {entityComplianceSelect ? (
                            <>
                                <Typography>Entity:{'\u00A0'}{'\u00A0'}{'\u00A0'}</Typography>
                                <Select
                                    labelId="entityComplianceSelectLabel"
                                    id="entityComplianceSelect"
                                    value={this.props.selectedEntityId}
                                    onChange={this.props.onEntitySelectClick}
                                    style={{ width: 150 }}
                                >
                                    {this.props.entitySelectData.map((el) => (
                                        <MenuItem key={el.entityId} value={el.entityId}>
                                            {el.entityName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : null}

                        {fieldComplianceSelect ? (
                            <>
                                <Typography>Field:{'\u00A0'}{'\u00A0'}{'\u00A0'}</Typography>
                                <Select
                                    labelId="fieldComplianceSelectLabel"
                                    id="entityComplianceSelect"
                                    value={this.props.selectedFieldId}
                                    onChange={this.props.onFieldSelectClick}
                                    style={{ width: 150 }}
                                >
                                    {this.props.fieldSelectData.map((el) => (
                                        <MenuItem key={el.fieldId} value={el.fieldId}>
                                            {el.fieldName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : null}

                        {download && RolePermissionService.DEFAULT_MAPPING_EXPORT.canView ? (
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Download to Excel" placement="bottom" arrow>
                                <IconButton onClick={this.props.onDownloadClick} color="secondary">
                                    {MatIconService.DOWNLOAD}
                                </IconButton>
                            </Tooltip>
                        ) : null}

                        {(add || addCircle) && RolePermissionService.DEFAULT_MAPPING_DETAIL.canCreate ? (
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Add" placement="bottom" arrow>
                                <IconButton onClick={this.props.onAddClick} color="secondary">
                                    {addCircle ? MatIconService.ADD_CIRCLE_OUTLINE : MatIconService.ADD}
                                </IconButton>
                            </Tooltip>
                        ) : null}

                        {reload ? (
                            <Tooltip enterDelay={MatSpecService.tooltipEnterDelay} title="Reload" placement="bottom" arrow>
                                <IconButton onClick={this.props.onReloadClick} color="secondary">
                                    {MatIconService.RELOAD}
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </Grid>
                    {noDivider || noDividerSpacing ? (
                        <span>{!noDivider && MatSpecService.showPageTitleDivider ? <Divider /> : null}</span>
                    ) : (
                        <span className={clsx(showButtons ? classes.pageHeaderDividerWithIcons : classes.pageHeaderDividerWithoutIcons)}>{MatSpecService.showPageTitleDivider ? <Divider /> : null}</span>
                    )}
                </Grid>
            </React.Fragment>
        );
    }
}


export default LayoutService.getHocComponenet(ComplianceDefaultMappingComponent);
